import { Controller } from "@hotwired/stimulus";

// 指定されたターゲットをクリックする。
export default class extends Controller {
  static targets = ["click"];

  do() {
    this.clickTargets.forEach((target) => target.click());
  }
}
